<template>
  <div>
    <b-overlay :show="show" rounded="sm" no-fade>
      <b-card-code title="Informasi Data Kavling">

        <!-- <b-row class="mt-2 mb-2" align="end">
          <b-col align="start" md="3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="showModalTambah()"
            >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Tambah</span>
            </b-button>
          </b-col>

          <b-col align="end" md="9"> </b-col>
        </b-row> -->

        <b-row class="mt-2 mb-2" align="end">
          <b-col md="3">
            <b-form-input
              id="name"
              v-model="name"
              placeholder="Cari Nama Kavling"
              @input="changeStatus()"
            />
          </b-col>
          <b-col md="3" align="start">
           
          </b-col>

          <b-col md="3"></b-col>

          <b-col align="end" md="3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="showModalTambah()"
            >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Tambah</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'created_date'">
              {{ props.row.created_date.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'create_time'">
              {{ props.row.create_time.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'block_sold_count'">
              {{ props.row.block_sold_count + props.row.block_installment_count }}
            </span>

            <span v-else-if="props.column.field === 'lineNumber'">
              {{ paging.page * 10 + (props.index + 1) }}.
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <!-- <div class="demo-inline-spacing"> -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  v-b-tooltip.hover.v-primary
                  title="Detail"
                  class="btn-icon mr-2"
                  @click="showModalDetail(props)"
                >
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  v-b-tooltip.hover.v-warning
                  title="Ubah"
                  class="btn-icon mr-2"
                  @click="showModalUbah(props)"
                >
                  <feather-icon icon="EditIcon" class="cursor-pointer" />
                </b-button>
                
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="danger"
                  v-b-tooltip.hover.v-danger
                  title="Hapus"
                  class="btn-icon"
                  @click="deleteInfo(props)"
                >
                  <feather-icon icon="Trash2Icon" class="cursor-pointer" />
                </b-button>
              <!-- </div> -->
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="paging.size"
                  :options="paging.perPageOptions"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap">
                  of {{ paging.total_elements }} entries
                </span>
              </div>

              <div>
                <b-pagination
                  :value="paging.page + 1"
                  :total-rows="paging.total_elements"
                  :per-page="paging.size"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>

        <!-- modal Form Kavling-->
        <b-modal
          id="modal-detail-notifikasi"
          ref="ref-form-data-kavling"
          title="Form Data Kavling"
          hide-footer="true"
          size="lg"
          no-close-on-backdrop
        >

        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group label-for="name">
                  <template v-slot:label>
                    Nama Kavling <span class="text-danger">*</span>
                  </template>

                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input 
                      id="name" 
                      v-model="formSubmit.name" 
                      placeholder="Masukkan Nama Kavling" 
                    />

                  <small class="text-danger">{{ errors[0]?'Nama Kavling Wajib Diisi':'' }}</small>

                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label-for="mc-first-name">
                  <template v-slot:label>
                    Provinsi <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    name="Provinsi"
                    rules="required"
                  >
                  <v-select
                    v-model="formSubmit.province"
                    :options="provinsiOptions"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :reduce="(name) => name.name"
                    placeholder="Pilih Provinsi"
                    @input="pilihProv($event)"
                  />
                  <small class="text-danger">{{ errors[0]?'Provinsi Wajib Diisi':'' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label-for="mc-last-name">
                  <template v-slot:label>
                    Kabupaten/ Kota <span class="text-danger">*</span>
                  </template>

                  <validation-provider
                    #default="{ errors }"
                    name="Kabupaten"
                    rules="required"
                  >
                  <v-select
                    v-model="formSubmit.city"
                    :options="kabupatenOptions"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :reduce="(name) => name.name"
                    placeholder="Pilih Kabupaten/ Kota"
                    @input="pilihKab($event)"
                  />
                  <small class="text-danger">{{ errors[0]?'Kabupaten Wajib Diisi':'' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label-for="mc-city">
                  <template v-slot:label>
                    Kecamatan <span class="text-danger">*</span>
                  </template>

                  <validation-provider
                    #default="{ errors }"
                    name="Kecamatan"
                    rules="required"
                  >
                  <v-select
                    id="mc-city"
                    v-model="formSubmit.district"
                    :options="kecamatanOptions"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :reduce="(name) => name.name"
                    placeholder="Pilih Kecamatan"
                    @input="pilihKec($event)"
                  />
                  <small class="text-danger">{{ errors[0]?'Kecamatan Wajib Diisi':'' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label-for="mc-city">
                  <template v-slot:label>
                    Kelurahan/ Desa <span class="text-danger">*</span>
                  </template>

                  <validation-provider
                    #default="{ errors }"
                    name="Kelurahan/ Desa"
                    rules="required"
                  >
                  <v-select
                    id="mc-city"
                    v-model="formSubmit.village"
                    :options="desaOptions"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :reduce="(name) => name.name"
                    placeholder="Pilih Kelurahan/ Desa"
                    @input="pilihDesa($event)"
                  />
                  <small class="text-danger">{{ errors[0]?'Kelurahan/ Desa Wajib Diisi':'' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label-for="mc-country">
                  <template v-slot:label>
                    Alamat <span class="text-danger">*</span>
                  </template>

                  <validation-provider
                    #default="{ errors }"
                    name="Alamat"
                    rules="required"
                  >
                  <b-form-textarea
                    rows="3"
                    v-model="formSubmit.address"
                    placeholder="Masukkan Alamat"
                  />
                  <small class="text-danger">{{ errors[0]?'Alamat Wajib Diisi':'' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Catatan" label-for="mc-country">
                  <b-form-textarea
                    rows="3"
                    v-model="formSubmit.note"
                    placeholder="Masukkan Catatan"
                  />
                </b-form-group>
              </b-col>
            </b-row>

          </b-form>

          <b-row class="mt-1 mb-2"> 
            <b-col align="end"
              >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="kembaliModalForm()"
                class="mr-2"
              >
                Batal
              </b-button>
              
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="submitForm()"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        </b-modal>
        <!-- end modal form kavling -->

        <!-- modal detail Form Kavling-->
        <b-modal
          id="modal-detail-notifikasi"
          ref="ref-detail-data-kavling"
          title="Form Detail Data Kavling"
          hide-footer="true"
          size="lg"
          no-close-on-backdrop
        >

        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group label-for="name">
                  <template v-slot:label>
                    Nama Kavling
                  </template>
                    <b-form-input 
                      id="name" 
                      v-model="formSubmit.name" 
                      placeholder="Masukkan Nama Kavling" 
                      disabled
                    />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label-for="mc-first-name">
                  <template v-slot:label>
                    Provinsi 
                  </template>

                  <b-form-input
                    id="name" 
                    v-model="formSubmit.province"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Kabupaten/ Kota" label-for="mc-last-name">
                  <b-form-input 
                    id="name" 
                    v-model="formSubmit.city"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Kecamatan" label-for="mc-city">
                  <b-form-input 
                    id="name" 
                    v-model="formSubmit.district"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label="Kelurahan/ Desa" label-for="mc-city">
                  <b-form-input 
                    id="name" 
                    v-model="formSubmit.village"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Alamat" label-for="mc-country">
                  <b-form-textarea
                    rows="3"
                    v-model="formSubmit.address"
                    placeholder="Masukkan Alamat Lengkap"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Catatan" label-for="mc-country">
                  <b-form-textarea
                    rows="3"
                    v-model="formSubmit.note"
                    placeholder="Masukkan Catatan"
                    disabled
                  />
                </b-form-group>
              </b-col>
            </b-row>

          </b-form>

          <b-row class="mt-1 mb-2"> 
            <b-col align="end"
              >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="kembaliModalDetail()"
                class="mr-2"
              >
                Kembali
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        </b-modal>
        <!-- end modal detail form kavling -->

      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      //here
      file: null,

      required,
      email,

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],

      paging: {
        page: 0,
        total_pages: 0,
        size: 10,
        total_elements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      columns: [
        {
          label: "No",
          field: "lineNumber",
          thClass: "text-center",
          tdClass: "text-center",
          width: "70px",
        },
        {
          label: "Nama Kavling",
          field: "name",
          thClass: "text-center",
          tdClass: "text-left",
          width: '250px',
        },
        {
          label: "Jumlah Blok",
          field: "block_count",
          thClass: "text-center",
          tdClass: "text-center",
          width: "100px",
        },
        {
          label: "Jumlah Terjual",
          field: "block_sold_count",
          thClass: "text-center",
          tdClass: "text-center",
          width: "100px",
        },
        {
          label: "Jumlah Tersedia",
          field: "block_available_count",
          thClass: "text-center",
          tdClass: "text-center",
          width: "100px",
        },
        {
          label: "Tanggal Dibuat",
          field: "create_time",
          thClass: "text-center",
          width: "150px",
        },
        {
          label: "Catatan",
          field: "note",
          thClass: "text-center",
          width: "250px",
        },
        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
          width: "200px",
        },
      ],
      rows: [],

      tanggal_filter: "",

      configs: {
        mode: "range",
        allowInput: {
          allowInput: true,
          dateFormat: 'Y-m-d'
        },
      },

      formSubmit: {
        name: "",
        province: "",
        city: "",
        district: "",
        village: "",
        address: "",
        note: ""
      },

      provinsiOptions: [],
      kabupatenOptions: [],
      kecamatanOptions: [],
      desaOptions: [],

      idProvTemp: "",
      idKabTemp: "",
      idKecTem: "",

      
      // old delete
      searchTerm: "",

      name: "",
      sort: "",

      create_time: "",

      getBerkas: {},

      show: false,
      aksi: "",
      id_kavling: "",

      nama: "",

      selected: null,

    };
  },

  beforeMount() {
    this.getData(this.paging.size, this.paging.page);
    this.getProvinsi();
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  created() {
    //here
  },

  methods: {
    getData(size, page) {
      this.show = true;

      let search0 = "";
      if (this.name) {
        search0 = "&name=" + this.name;
      } else {
        search0;
      }

      return new Promise(() => {
        axios
          .get(
            "v1/kavling/all?" +
              "page=" +
              page +
              "&size=" +
              size +
              "&sort_by=name&sort_dir=1" +
              search0
          )
          .then((res) => {
            this.rows = res.data.content;

            this.paging.page = res.data.page;
            this.paging.size = res.data.size;
            this.paging.total_elements = res.data.total_elements;
            this.paging.total_pages = res.data.total_pages;

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    showModalTambah() {
      this.aksi = "tambahData";

      this.formSubmit.name = "";
      this.formSubmit.province = "";
      this.formSubmit.city = "";
      this.formSubmit.district = "";
      this.formSubmit.village = "";
      this.formSubmit.address = "";
      this.formSubmit.note = "";

      this.$refs["ref-form-data-kavling"].show();
    },

    showModalDetail(props) {
      return new Promise(() => {
        axios
          .get(
            "v1/kavling/" + props.row._id
          )
          .then((res) => {
            this.formSubmit.name = res.data.name;
            this.formSubmit.province = res.data.province;
            this.formSubmit.city = res.data.city;
            this.formSubmit.district = res.data.district;
            this.formSubmit.village = res.data.village;
            this.formSubmit.address = res.data.address;
            this.formSubmit.note = res.data.note;

            this.$refs["ref-detail-data-kavling"].show();

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    showModalUbah(props) {
      return new Promise(() => {
        axios
          .get(
            "v1/kavling/" + props.row._id
          )
          .then((res) => {
            this.id_kavling = res.data._id;
            this.formSubmit.name = res.data.name;
            this.formSubmit.province = res.data.province;
            this.formSubmit.city = res.data.city;
            this.formSubmit.district = res.data.district;
            this.formSubmit.village = res.data.village;
            this.formSubmit.address = res.data.address;
            this.formSubmit.note = res.data.note;

            this.$refs["ref-form-data-kavling"].show();
            this.aksi = "ubahData";

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    kembaliModalForm(){
      this.$refs["ref-form-data-kavling"].hide();
      this.show = false;
    },

    kembaliModalDetail(){
      this.$refs["ref-detail-data-kavling"].hide();
      this.show = false;
    },

    submitForm(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.aksi === "tambahData") {
            return new Promise(() => {
              axios
                .post("v1/kavling", this.formSubmit)
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Tambah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Tambah Data Kavling Berhasil`,
                    },
                  });
                  this.getData(this.paging.size, this.paging.page);
                  this.$refs["ref-form-data-kavling"].hide();
                  this.show = false;
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                  }
                  this.show = false;
                });
            });
          } else {
            return new Promise(() => {
              axios
                .put("v1/kavling/" + this.id_kavling, this.formSubmit)
                .then((res) => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Ubah Data Berhasil`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Ubah Data Kavling Berhasil`,
                    },
                  });
                  this.getData(this.paging.size, this.paging.page);
                  this.$refs["ref-form-data-kavling"].hide();
                  this.show = false;
                })
                .catch((error) => {
                  if (error.response.status == 401) {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                    this.$router.push("/login");
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: error.response.data.detail,
                        icon: "XCircleIcon",
                        variant: "danger",
                      },
                    });
                    this.show = false;
                  }
                  this.show = false;
                });
            });
          }
        }
      })
    },

    deleteInfo(props) {
      this.$swal({
        title: "Apakah Anda Yakin ?",
        text: "Data yang terhapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteApi(props);
        }
      });
    },

    deleteApi(props) {
      return new Promise(() => {
        axios
          .delete("v1/kavling/" + props.row._id)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hapus Data Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Hapus Data Kavling Berhasil`,
              },
            });
            this.getData(this.paging.size, this.paging.page);
          })
          .catch((error) => {
              if (error.response.status == 401) {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
                this.$router.push("/login");
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.detail,
                    icon: "XCircleIcon",
                    variant: "danger",
                  },
                });
                this.show = false;
              }
              this.show = false;
            });
            
      });
    },

    changeStatus() {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    getProvinsi() {
      return new Promise(() => {
        axios.get("v1/region/getAllProvinces").then((res) => {
          this.provinsiOptions = res.data;
        });
      });
    },

    pilihProv(event) {
      this.formSubmit.province = event;
      this.getKabupaten();
    },

    getKabupaten() {
      var indexProv = this.provinsiOptions.findIndex(
        (x) => x.name === this.formSubmit.province
      );

      if (indexProv != null) {
        var provId = this.provinsiOptions[indexProv].id;

        return new Promise(() => {
          axios.get("v1/region/getRegencies/" + provId).then((response) => {
            this.kabupatenOptions = response.data;
          });
        });
      }
    },

    pilihKab(event) {
      this.formSubmit.regency = event;
      this.getKecamatan();
    },

    getKecamatan() {
      var indexKab = this.kabupatenOptions.findIndex(
        (x) => x.name === this.formSubmit.regency
      );
      if (indexKab != null) {
        var kabupatenId = this.kabupatenOptions[indexKab].id;

        return new Promise(() => {
          axios
            .get("v1/region/getDistricts/" + kabupatenId)
            .then((response) => {
              this.kecamatanOptions = response.data;
            });
        });
      }
    },

    pilihKec(event) {
      this.formSubmit.district = event;
      this.getDesa();
    },

    getDesa() {
      var indexKec = this.kecamatanOptions.findIndex(
        (x) => x.name === this.formSubmit.district
      );
      if (indexKec != null) {
        var kecamatanId = this.kecamatanOptions[indexKec].id;

        return new Promise(() => {
          axios
            .get("v1/region/getVillages/" + kecamatanId)
            .then((response) => {
              this.desaOptions = response.data;
            });
        });
      }
    },

    pilihDesa(event) {
      this.formSubmit.village = event;
    },
    // end region

    // get Temporary
    getTempProv() {
      return new Promise(() => {
        axios.get("v1/wilayah/getAllProvinces").then((response) => {
          this.provinsiOptions = response.data;

          let indexProv = this.provinsiOptions.findIndex(
            (x) => x.name === this.formSubmit.province
          );

          return new Promise(() => {
            axios.get("/v1/wilayah/getAllProvinces").then((response) => {
              this.idProvTemp = response.data[indexProv].id;

              this.getTempKab();
            });
          });
        });
      });
    },

    // untuk mendapatkan kabupaten dari get id area
    getTempKab() {
      return new Promise(() => {
        axios
          .get("/v1/wilayah/getRegencies/" + this.idProvTemp)
          .then((response) => {
            this.kabupatenOptions = response.data;

            let indexKab = this.kabupatenOptions.findIndex(
              (x) => x.name === this.formSubmit.regency
            );

            return new Promise(() => {
              axios
                .get("/v1/wilayah/getRegencies/" + this.idProvTemp)
                .then((response) => {
                  this.idKabTemp = response.data[indexKab].id;
                  this.getTempKec();
                });
            });
          });
      });
    },

    getTempKec() {
      return new Promise(() => {
        axios
          .get("/v1/wilayah/getDistricts/" + this.idKabTemp)
          .then((response) => {
            this.kecamatanOptions = response.data;
          });
      });
    },

    handleSearch(searching) {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },
    handleChangePage(value) {
      const page = value - 1;
      this.getData(this.paging.size, page);
    },
    handlePageChange(active) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    onSortChange(params) {
      this.sort = `${params[0].field}`;
      this.getData(this.paging.size, this.paging.page);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
